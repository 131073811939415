<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" type="daterange" v-model="time" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Sports'}">统计显示</router-link>
          <router-link :to="{name: 'SportsTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'SportsChart'}">图表显示</router-link>
        </div>
      </div>
      <Row :gutter="40">
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg1">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-7.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="sum_total + 'kcal'"><em class="font_30">{{ sum_total }}</em>kcal</p>
                    <h2 title="总消耗">总消耗</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-7.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="sum_effective + 'kcal'"><em class="font_30">{{sum_effective}}</em>kcal</p>
                    <h2 title="总有效消耗量">总有效消耗量</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg2">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-9.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="sum_step + '步'"><em class="font_30">{{sum_step}}</em>步</p>
                    <h2 title="总运动步数">总运动步数</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon_11.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="sum_time + 'min'"><em class="font_30">{{sum_time}}</em>min</p>
                    <h2 title="总运动时长">总运动时长</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col :lg="{span: 8}" :md="{span: 12}">
          <div class="sports-card bg3">
            <Row>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon-8.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="avg_time + 'min'"><em class="font_30">{{avg_time}}</em>min</p>
                    <h2 title="平均每日运动时长">平均每日运动时长</h2>
                  </Col>
                </Row>
              </Col>
              <Col :lg="{span: 12}">
                <Row type="flex" justify="center" align="middle">
                  <Col span="8"><img src="@/assets/img/icon_10.png" alt="" width="40px" height="40px"></Col>
                  <Col span="16">
                    <p :title="avg_step + '步'"><em class="font_30">{{avg_step}}</em>步</p>
                    <h2 title="平均每日运动步数">平均每日运动步数</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			sportsData: {
				index: 0,
				select_date: '',
				page: 1,
				size: 10,
			},
			sum_step: '',
			sum_total: '',
			avg_time: '',
			sum_time: '',
			avg_step: '',
			sum_effective: '',
			time: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		firstDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-01`;
		},
		nowDate() {
			let d = new Date();
			let month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			let day = d.getDate();
			day = day > 9 ? day : '0' + day;
			return `${d.getFullYear()}-${month}-${day}`;
		},
		last30Date() {
			// 获取当前日期
			var myDate = new Date();

			// 获取三十天前日期
			var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);
			var lastY = lw.getFullYear();
			var lastM = lw.getMonth() + 1;
			var lastD = lw.getDate();
			var startdate =
				lastY + '-' + (lastM < 10 ? '0' + lastM : lastM) + '-' + (lastD < 10 ? '0' + lastD : lastD);

			return startdate;
		},
	},
	methods: {
		handleInit() {
			this.sportsData.member_id = this.memberId;
			MemberService.sportsQuery(this.sportsData).then((data) => {
				if (
					data.sta_show.avg_time === null ||
					data.sta_show.avg_step === null ||
					data.sta_show.sum_step === null ||
					data.sta_show.sum_total === null ||
					data.sta_show.sum_time === null ||
					data.sta_show.sum_effective === null
				) {
					this.avg_time = 0;
					this.avg_step = 0;
					this.sum_effective = 0;
					this.sum_step = 0;
					this.sum_total = 0;
					this.sum_time = 0;
				} else {
					this.avg_time = data.sta_show.avg_time.toFixed(1);
					this.avg_step = data.sta_show.avg_step.toFixed(1);
					this.sum_step = data.sta_show.sum_step;
					this.sum_total = data.sta_show.sum_total;
					this.sum_time = data.sta_show.sum_time;
					this.sum_effective = data.sta_show.sum_effective;
				}
			});
		},
		handleChangeDate(value) {
			this.sportsData.select_date = value.join(',');
			this.handleInit();
		},
	},
	mounted() {
		this.time = [this.last30Date, this.nowDate];
		this.sportsData.select_date = this.time.join(',');
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.scheme-contain .data-contain {border: none;}
.sports-card {
  padding: 55px 25px;
  color: #fff;
  border-radius: 5px;
}
.sports-card h2, .sports-card p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
